<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Name"
          v-model="record.Name"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Longitude"
          v-model="record.Longitude"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Latitude"
          v-model="record.Latitude"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Address"
          v-model="record.Address"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.location_id"
          item-text="Location"
          item-value="id"
          label="Locations"
          :items="Locations"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    loader: false,
    Locations: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    getLocData() {
      const self = this;
      this.$store
        .dispatch("get", `/locations`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.Locations = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
  },
  created() {
    this.getLocData();
  },
};
</script>